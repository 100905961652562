/* eslint-disable no-unused-vars */
import ReportTable from "components/Report";
import { dateSort } from "components/Report/helpers";
import { StatusCards } from "components/StatusCard";
import ActivationForm from "containers/Company/components/ActivationForm/activationForm";
import DetailCompanyProviderComp from "containers/Company/components/DetailCompanyProvider";
import OnBoardBatchModal from "containers/Company/components/OnBoardBatchModal";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import moment from "moment";
import React, { createRef, useEffect, useRef, useState } from "react";
import { AiOutlineCheckSquare, AiOutlineReload } from "react-icons/ai";
import { CgAdd, CgDanger } from "react-icons/cg";
import { FaEye } from "react-icons/fa";
import { GrCheckbox } from "react-icons/gr";
import { MdOpenInNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	UncontrolledTooltip
} from "reactstrap";
import AccessForm from "../../../../components/AcessForm";
import * as actions from "./actions";
import * as api from "services/api";

dayjs.extend(customDateFormat);

function onBoardDetail({ companyId, companyCnpj }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { item, hireds, loading, dashboard } = useSelector(
		c => c.onBoardDetail
	);
	const { item: company } = useSelector(s => s.companyDetail);

	const { token } = useSelector(s => s.login);

	const [modal, setModal] = useState(false);

	const [modalToggle, setModalToggle] = useState(false);
	const toggleModal = () => setModalToggle(!modalToggle);

	const [currentProvider, setCurrentProvider] = useState({});

	const [hiredList, setHiredList] = useState([]);

	const [allHireds, setAllHireds] = useState([]);
	const [allHiredsCount, setAllHiredsCount] = useState(0);

	const [pages, setPages] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [selectAll, setSelectAll] = useState(false);
	const toggleSelectAll = () => setSelectAll(!selectAll);
	const [tableSt, setTablSt] = useState({});

	const [modalAction, setModalAction] = useState("");
	const [modalActivate, setModalActivate] = useState(false);
	const toggleModalActivate = () => setModalActivate(!modalActivate);

	const [CompanyProviderStatus, setCompanyProviderStatus] = useState(0);

	const hasReachedMaximumPurchasedProviders =
		dashboard?.enabled + dashboard?.onboard + dashboard?.pending >=
		item?.purchasedProviders;

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, callback } = tableState;
		setTablSt(tableState);

		if (!sorted) sorted = [];

		dispatch(
			actions.getOnboard(
				companyId,
				page * pageSize,
				pageSize,
				`&filters[mei]=${companyId}` +
					filtered.reduce((p, c) => {
						return p + `&filters[${c.id}]=${c.value}`;
					}, ""),
				sorted[0] ? sorted[0].id : undefined,
				sorted[0] ? sorted[0].desc : undefined,
				callback
			)
		);
	};

	const createUpdateOnboard = () => {
		dispatch(actions.createUpdateOnboard(companyCnpj));
		history.go(0);
	};

	const createBadge = status => {
		if (status == 0) {
			return (
				<Badge color={"secondary"} className={"w-100"}>
					Pendente
				</Badge>
			);
		} else if (status == 1) {
			return (
				<Badge color={"info"} className={"w-100"}>
					OnBoard
				</Badge>
			);
		} else if (status == 2) {
			return (
				<Badge color={"success"} className={"w-100"}>
					Ativo
				</Badge>
			);
		} else if (status == 3) {
			return (
				<Badge color={"danger"} className={"w-100"}>
					Inativo
				</Badge>
			);
		} else {
			return "PENDENTE";
		}
	};

	const firstLoginBadge = first => {
		if (!first) {
			return (
				<Badge color={"success"} className={"w-100"}>
					Ok
				</Badge>
			);
		} else {
			return (
				<Badge color={"secondary"} className={"w-100"}>
					Pendente
				</Badge>
			);
		}
	};

	const userBadge = providerUser => {
		return (
			<>
				<Badge
					style={{
						cursor: "pointer"
					}}
					color={"primary"}
					target={"_blank"}
					onClick={() => {
						let win = window.open(`/client/${providerUser._id}`, "_blank");
						win.focus();
					}}
					className={"mr-1"}
				>
					<MdOpenInNew size={16} />
				</Badge>
				{`${providerUser?.name} ${providerUser?.lastname || ""}`}
			</>
		);
	};

	const companyBadge = provider => {
		return (
			<>
				<Badge
					style={{
						cursor: "pointer"
					}}
					color={"primary"}
					target={"_blank"}
					onClick={() => {
						let win = window.open(`/companies/${provider._id}`, "_blank");
						win.focus();
					}}
					className={"mr-1"}
				>
					<MdOpenInNew size={16} />
				</Badge>
				{provider?.businessName || ""}
			</>
		);
	};

	const toggle = () => {
		setModal(!modal);
	};

	const onSubmit = async data => {
		toggle();

		let payload = {
			...data,
			phoneNumber: data.phoneNumber
				.match(/\d/g)
				.join("")
				.trim(),
			bDate: dayjs(data.bDate, "DD/MM/YYYY")
		};
		dispatch(
			await actions.hiredOnBoard(
				{
					hired: payload,
					company_id: companyId
				},
				(err, model) => {
					if (err) {
						console.log(err);
						if (err.default === "Usuário já cadastrado") {
							alert(
								"O email que você tentou cadastrar já está cadastrado. Por favor, reative o usuário."
							);
						}
					}

					if (model.exist)
						alert(
							"Um usuário com este email foi adicionado: " + model.user.email
						);
					else
						alert(
							"Um novo usuário com este email foi cadastrado: " +
								model.user.email
						);

					window.location.reload();
				}
			)
		);
	};

	const headerButtons = () => {
		return (
			<div className="d-flex">
				<Button
					style={{
						color: "#fff",
						marginRight: 10,
						backgroundColor: "#28a745"
					}}
					onClick={() => {
						setModalAction("Ativar");
						toggleModalActivate();
						setCompanyProviderStatus(2);
					}}
					id={"activate"}
					disabled={hasReachedMaximumPurchasedProviders}
				>
					<UncontrolledTooltip target={"activate"}>
						Ativar Selecionados
					</UncontrolledTooltip>
					<CgDanger style={{ marginRight: "10" }} />
					Ativar
				</Button>

				<Button
					color="info"
					style={{
						color: "#fff",
						marginRight: 10
					}}
					onClick={() => {
						setModalAction("Iniciar OnBoard");
						toggleModalActivate();
						setCompanyProviderStatus(1);
					}}
					id={"onboarding"}
					disabled={hasReachedMaximumPurchasedProviders}
				>
					<UncontrolledTooltip target={"onboarding"}>
						Iniciar Onboard Selecionados
					</UncontrolledTooltip>
					<CgDanger style={{ marginRight: "10" }} />
					Onboard
				</Button>

				<Button
					color={"danger"}
					style={{
						color: "#fff",
						marginRight: 10
					}}
					onClick={() => {
						setModalAction("Inativar");
						toggleModalActivate();
						setCompanyProviderStatus(3);
					}}
					id={"inactivate"}
				>
					<UncontrolledTooltip target={"inactivate"}>
						Inativar Selecionados
					</UncontrolledTooltip>
					<CgDanger style={{ marginRight: "10" }} />
					Inativar
				</Button>

				{/* <OnBoardBatchModal company_id={companyId} /> */}

				<Button
					color={"primary"}
					style={{
						color: "#fff"
					}}
					onClick={() => {
						toggle();
					}}
				>
					<CgAdd className={"mr-2"} />
					Adicionar Prestador
				</Button>
			</div>
		);
	};

	async function getProvidersByBusiness(tableState) {
		if (!tableState) return;

		createRef(tableState);
		const { page, pageSize, sorted, filtered } = tableState;

		setIsLoading(true);
		setAllHiredsCount(0);

		try {
			const response = await api.sendGet(
				`/companyProvider/providers-info/${companyId}?skip=${pageSize *
					page}&take=${pageSize}&sort=${sorted[0].id}&desc=${
					sorted[0].desc
				}${filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, "") ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setAllHireds(result.model);
				setAllHiredsCount(result.params.count);

				setPages(Math.ceil(result.params.count / pageSize));
			}
		} catch (error) {
			console.log(error.massage);
		}

		setIsLoading(false);
	}

	const checkBoxComponent = c => {
		return (
			<Input
				type="checkbox"
				style={{
					margin: 0
				}}
				onChange={el => {
					if (el.currentTarget.checked) {
						hiredList.push(c);
					} else if (!el.currentTarget.checked) {
						let index = hiredList.indexOf(c);
						hiredList.splice(index, 1);
					}
				}}
				// disabled={c.providerUser ? false : true}
				id={"providerCheckBox"}
			/>
		);
	};

	const submitOnboardSelect = () => {
		if (confirm("Você deseja iniciar o onboard de todos os selecionados?")) {
			dispatch(
				actions.submitOnboard({
					batch: false,
					company_id: companyId,
					hiredList: hiredList
				})
			);
		}
	};

	const submitOnboard = () => {
		if (
			confirm("Você deseja iniciar o onboard de todos com status pendente?")
		) {
			dispatch(
				actions.submitOnboard({
					batch: true,
					company_id: companyId
				})
			);
		}

		alert("OnBard Prestadores Iniciado! Emails enviados.");
		window.location.reload();
	};

	const submitInactivate = data => {
		if (hiredList.length == 0) {
			alert(
				"Você precisa selecionar ao menos um prestador para ativar/inativar"
			);
			return;
		}

		if (hiredList.length > 100) {
			alert(
				"Você selecionou muitos prestadores de uma vez, tente novamente com menos de 100!"
			);
			history.go(0);
			return;
		}

		if (confirm("Deseja realmente Ativar/Desativar Prestadores?")) {
			dispatch(
				actions.submitInactivateMany(
					{
						hired: hiredList.map(e => {
							const hired = { _id: e._id };
							if (e.providerUser) {
								hired.providerUser = { _id: e.providerUser?._id };
							}

							if (e.provider && e.provider.cnpj) {
								hired.provider = { cnpj: e.provider.cnpj };
							}

							return hired;
						}),
						statusDetail: CompanyProviderStatus,
						note: data.note,
						hirer: company
					},
					err => {
						if (err) alert(err);
						else {
							history.go(0);
						}
					}
				)
			);
		}
	};

	const submitOnboardHirer = () => {
		dispatch(
			actions.submitOnboardHirer({
				mainUsers: item.mainUsers,
				_id: item._id
			})
		);
		alert("OnBard Contratante Iniciado! Emails enviados.");
		window.location.reload();
	};

	const modalAccessFormInitialValues = {
		useOwnContracts: company.useOwnContracts
	};

	const onClickSelectAllCheckbox = el => {
		const providersChks = document.querySelectorAll("#providerCheckBox");

		if (selectAll) {
			setHiredList([]);
			providersChks.forEach(e => (e.checked = false));
		} else {
			setHiredList(hireds);
			providersChks.forEach(e => (e.checked = true));
		}
	};

	const setFilterOnTable = filter => {
		const getStatus = new Map();
		getStatus.set("Pendentes", "0");
		getStatus.set("Onboard", "1");
		getStatus.set("Ativos", "2");
		getStatus.set("Inativos", "3");

		let { page, pageSize, sorted, filtered, toExport, callback } = tableSt;

		const fill =
			filter == "Prestadores"
				? ""
				: `&filters[status]=${getStatus.get(filter)}`;

		dispatch(
			actions.getOnboard(
				companyId,
				page * pageSize,
				pageSize,
				`&filters[mei]=${companyId}` + fill,
				sorted[0] ? sorted[0].id : undefined,
				sorted[0] ? sorted[0].desc : undefined,
				callback
			)
		);
	};

	useEffect(() => {
		dispatch(actions.getCompanyProvidersStatus(companyId));
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row className="">
				<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
					<h4>
						<strong>OnBoard</strong>
					</h4>
				</Col>

				<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
					<Badge
						style={{
							cursor: "pointer"
						}}
						color={"primary"}
						onClick={createUpdateOnboard}
					>
						<AiOutlineReload /> Reload
					</Badge>
				</Col>
			</Row>

			<Row>
				<StatusCards
					cardsData={[
						{
							title: "Prestadores",
							color: "info",
							count: dashboard?.total || 0,
							onClick: setFilterOnTable
						},
						{
							title: "Ativos",
							color: "success",
							count: dashboard?.enabled || 0,
							onClick: setFilterOnTable
						},
						{
							title: "Onboard",
							color: "warning",
							count: dashboard?.onboard || 0,
							onClick: setFilterOnTable
						},
						{
							title: "Pendentes",
							color: "secondary",
							count: dashboard?.pending || 0,
							onClick: setFilterOnTable
						},
						{
							title: "Inativos",
							color: "danger",
							count: dashboard?.disabled || 0,
							onClick: setFilterOnTable
						}
					]}
				/>
			</Row>

			<Row>
				<Col>
					<ReportTable
						manual
						filterable
						tableRef={table}
						title={"Prestadores"}
						count={allHiredsCount}
						data={allHireds}
						pages={pages}
						loading={isLoading}
						defaultPageSize={10}
						noExport={true}
						headerRightComponent={headerButtons()}
						onFetchData={getProvidersByBusiness}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Check",
								id: "select",
								accessor: c => checkBoxComponent(c),
								Filter: () => {
									return (
										<>
											<div
												id="selectAllCheckBox"
												onClick={() => {
													toggleSelectAll();
													onClickSelectAllCheckbox();
												}}
												style={{ cursor: "pointer" }}
											>
												{selectAll ? (
													<AiOutlineCheckSquare color={"primary"} size={20} />
												) : (
													<GrCheckbox size={18} />
												)}

												<UncontrolledTooltip target={"selectAllCheckBox"}>
													Selecionar Todos
												</UncontrolledTooltip>
											</div>
										</>
									);
								},
								width: 50,
								className: "justify-content-center",
								headerClassName: "align-self-center",
								sortable: false
							},
							{
								Header: "Detalhes",
								filterable: false,
								id: "details",
								accessor: c => {
									return (
										<>
											<Button
												id={`tooltip1-${c._id}`}
												outline
												onClick={() => {
													setCurrentProvider(c);
													toggleModal();
												}}
												className={"m-0 p-0 pl-2 pr-2"}
											>
												<FaEye />
											</Button>
											<UncontrolledTooltip target={`tooltip1-${c._id}`}>
												Detalhes do Prestador
											</UncontrolledTooltip>
										</>
									);
								},
								width: 65,
								className: "justify-content-center",
								sortable: false
							},
							{
								Header: "Prestador",
								id: "providerUser.name",
								accessor: ({ providerUser }) =>
									providerUser ? userBadge(providerUser) : "",
								width: 300,
								sortable: false
							},
							{
								Header: "Empresa",
								id: "provider.businessName",
								accessor: ({ provider }) =>
									provider ? companyBadge(provider) : "",
								width: 300,
								sortable: false
							},
							{
								Header: "Status",
								id: "status",
								accessor: ({ status }) => createBadge(status),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys([
												"Pendente",
												"OnBoard",
												"Ativo",
												"Inativo"
											]).map(c => (
												<option key={c} value={c}>
													{["Pendente", "OnBoard", "Ativo", "Inativo"][c]}
												</option>
											))}
										</select>
									);
								},
								width: 130
							},
							{
								Header: "Primeiro Acesso",
								id: "providerUser.firstLogin",
								accessor: ({ providerUser }) =>
									firstLoginBadge(
										providerUser ? providerUser?.firstLogin : true
									),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{[true, false].map(c => (
												<option key={c} value={c}>
													{c ? "Sim" : "Não"}
												</option>
											))}
										</select>
									);
								},
								sortable: false,
								width: 120
							},
							{
								Header: "Data Primeiro Acesso",
								id: "providerUser.firstLoginDate",
								accessor: ({ providerUser }) =>
									providerUser?.firstLoginDate
										? moment(providerUser?.firstLoginDate).format(
												"DD/MM/YYYY HH:mm"
										  )
										: "",
								width: 120
							},
							{
								Header: "Data de Criação",
								id: "createdAt",
								accessor: ({ createdAt }) =>
									createdAt
										? dayjs(createdAt).format(ls.dateFormatWithoutSeconds)
										: "",
								sortMethod: dateSort,
								width: 120
							},
							{
								Header: "Criado por:",
								filterable: false,
								id: "createdBy.name",
								accessor: ({ createdBy }) => createdBy?.name || "",
								width: 120,
								sortable: false
							},
							{
								Header: "Data de Ativação",
								id: "activatedAt",
								accessor: ({ activatedAt }) =>
									activatedAt
										? dayjs(activatedAt).format(ls.dateFormatWithoutSeconds)
										: "",
								sortMethod: dateSort,
								width: 120
							},
							{
								Header: "Ativado por:",
								filterable: false,
								id: "activatedBy.name",
								accessor: ({ activatedBy }) => activatedBy?.name || "",
								width: 120,
								sortable: false
							},
							{
								Header: "Data de Inativação",
								id: "inactivatedAt",
								accessor: ({ inactivatedAt }) =>
									inactivatedAt
										? dayjs(inactivatedAt).format(ls.dateFormatWithoutSeconds)
										: "",
								sortMethod: dateSort,
								width: 120
							},
							{
								Header: "Inativado por:",
								filterable: false,
								id: "inactivatedBy.name",
								accessor: ({ inactivatedBy }) => inactivatedBy?.name || "",
								width: 120,
								sortable: false
							},
							{
								Header: "Última Atualização",
								id: "updatedAt",
								accessor: ({ updatedAt }) =>
									updatedAt
										? dayjs(updatedAt).format(ls.dateFormatWithoutSeconds)
										: "",
								sortMethod: dateSort,
								width: 120
							},
							{
								Header: "Atualizado por:",
								filterable: false,
								id: "updatedBy.name",
								accessor: ({ updatedBy }) => updatedBy?.name || "",
								width: 130,
								sortable: false
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalToggle} toggle={toggleModal} size={"xl"}>
				<ModalHeader toggle={toggleModal} className={"card__title bold-text"}>
					Detalhes do Prestador
				</ModalHeader>
				<ModalBody>
					<DetailCompanyProviderComp companyProvider={currentProvider} />
				</ModalBody>

				<ModalFooter>
					<Button onClick={toggleModal}>Fechar</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle} className={"card__title bold-text"}>
					Adicionar Prestador
				</ModalHeader>

				<ModalBody className="theme-light">
					<AccessForm
						onSubmit={onSubmit}
						initialValues={modalAccessFormInitialValues}
					/>
				</ModalBody>
			</Modal>

			<Modal isOpen={modalActivate} toggle={toggleModalActivate}>
				<ModalHeader
					toggle={toggleModalActivate}
					className={"card__title bold-text"}
				>
					Você deseja <strong>{modalAction}</strong> estes prestadores?
				</ModalHeader>

				<ModalBody className="theme-light pl-3">
					<ReportTable
						manual
						tableRef={table}
						noTitle={true}
						data={hiredList}
						pageSize={hiredList.length}
						noExport={true}
						showPagination={false}
						columns={[
							{
								Header: "Nome",
								id: "name",
								accessor: ({ providerUser }) => providerUser?.name
							}
						]}
					/>

					<Row>
						<Col>
							<ActivationForm
								action={modalAction}
								onSubmit={submitInactivate}
							/>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default onBoardDetail;
